import styled, {css} from "styled-components";
import {COLOR_DARK, COLOR_ORANGE} from "../../styles";

export const HeaderBlock = styled.section<{$state?: boolean}>`
    width: 100%;
    height: 80px;
    border-bottom: 2px solid black;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: whitesmoke;
 
    ${props => props.$state && css`

        border-bottom: none;
    `}
`
export const HeaderTitle = styled.div`
    display: flex;

    padding: 5px 30px 0 15px;

    img {
        height: 70px;
        
    }

    a {
        display: flex;
        font-family: Gorditas, 'sans-serif';
        font-size: 42px;
        color: ${COLOR_DARK};
        font-weight: bold;

        span {
            font-family: Gorditas, 'sans-serif';
            color: ${COLOR_ORANGE};
            font-weight: bold;
        }

        &:hover {
            color: red;

            span {
                color: red;
            }
        }
    }
`
export const HeaderLinks = styled.div<{$state?: string}>`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 200px;

    a {
        min-width: max-content;
        width: 110px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        transition: 0.5s;
        margin: 0 5px;
    }
    
    a:hover {
        transition: 0.3s;
        transform: scale(1.2);
        text-shadow: #000000 5px 5px 5px;
    }

    a:active {
        color: #ff0000;
    }
    
    .${props => props.$state} {
        background:  ${COLOR_ORANGE};
        border-radius: 50%;
        
        &:hover {
            transform: none;
            text-shadow: none;
            color: black;
        }
        &:active {
            color: black;
        }
    }
    
    @media (max-width: 1000px) {

        display: none;

    }
`
export const FutureBlock = styled.div`
    @media (max-width: 1000px) {

        display: none;

    }
`

export const BurgerButton = styled.div<{$state?: boolean}> `
    display: none;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 50px;
    min-width: 50px;
    height: 50px;

    transition: 0.5s;
    margin: auto 30px auto 0;

    span {
        width: 100%;
        height: 8px;
        background: black;
        border-radius: 5px;
        transition: 0.5s;
        caret-color: rgba(255, 255, 255, 0);
    }

    ${props => props.$state && css`

        span:first-of-type {
            transform: rotate(-45deg) translate(-8px, 10px);
        }

        span:last-of-type {
            transform: rotate(45deg) translate(-7px, -10px);
        }

        span:nth-last-of-type(2) {
            display: none;
        }

    `}
    &:hover {
        cursor: pointer;

        span {

            background: #ffc400;
        }


    }

    @media (max-width: 1000px) {

        display: flex;

    }
`

export const WindowClose = styled.div `
    position: fixed;
    z-index: 98;
    top:80px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(128, 128, 128, 0.52);

`