import React from 'react';
import {FirstTitle, HomeTitleBlock, LogoTitle, LogoTitleContent, LogoTitleUl, SecondTitle} from "./style";
import Slider from "react-slick";


const HomeTitle = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false
    };

    return (
        <HomeTitleBlock>
            <FirstTitle>
                <h1>Электромонтажные работы в Минске и Минской области!</h1>
            </FirstTitle>
            <LogoTitle>
                <Slider {...settings}>
                    <div className={'logo_img_block'}>
                        <img src="/img/slider_logo/image1.jpg" alt=""/>
                    </div>
                    {/*<div className={'logo_img_block'}>*/}
                    {/*    <img src="/img/slider_logo/image2.jpg" alt=""/>*/}
                    {/*</div>*/}
                    <div className={'logo_img_block'}>
                        <img src="/img/slider_logo/image3.jpg" alt=""/>
                    </div>
                    <div className={'logo_img_block'}>
                        <img src="/img/slider_logo/image4.jpg" alt=""/>
                    </div>
                    <div className={'logo_img_block'}>
                        <img src="/img/slider_logo/image5.jpg" alt=""/>
                    </div>
                    <div className={'logo_img_block'}>
                        <img src="/img/slider_logo/image6.jpg" alt=""/>
                    </div>
                    <div className={'logo_img_block'}>
                        <img src="/img/slider_logo/image7.jpg" alt=""/>
                    </div>
                </Slider>
                <LogoTitleContent>
                    <LogoTitleUl>
                        <li>Выполняем любые виды электромонтажных работ!</li>
                        <li>Замена электропроводки под ключ</li>
                    <li>Установка розеток и выключателей</li>
                    <li>Монтаж любых систем освещения (люстры, бра, светодиодные ленты и т.д.)</li>
                    <li>Монтаж электрощитов и установка автоматов</li>
                    <li>Монтаж теплого пола</li>
                    <li>Консультация по телефону бесплатно!</li>
                    </LogoTitleUl>
                </LogoTitleContent>
            </LogoTitle>

            <SecondTitle>
                <h2>Услуги электрика</h2>
            </SecondTitle>
        </HomeTitleBlock>
    );
};

export default HomeTitle;