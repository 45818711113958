import {FixedFooterBlock} from "./slyle";


const FixedFooter = () => {

    return (
        <FixedFooterBlock>

        </FixedFooterBlock>
    );
};

export default FixedFooter;