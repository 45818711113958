import React from 'react';
import {ServicesBlock, ServicesContent, ServicesContentCard, ServicesContentDescription} from "./style";
import SEO from "../../../constants/SEO";
import {useNavigate} from "react-router-dom";

const Services = () => {
    const navigate = useNavigate();

    const openImage = (name: string) => {

        if (name) {
            navigate(`/services/${name}`);
            window.scroll({top: 0})
        }
    }

    return (
        <ServicesBlock>
            <SEO
                title={'Услуги электрика в Минске и Минской области | ElectroHelp.by'}
                description={'Замена электропроводки, автоматов, освещения. Установка розеток и выключателей. Повесить люстру, бра и другое освещение. Электромонтажные работы любой сложности!'}/>
            <h1>Наши услуги!</h1>

            <ServicesContent>
                <ServicesContentCard onClick={() => openImage('zamena-staroy-elektroprovodki')}>

                    <h3>Замена старой электропроводки</h3>

                    <ServicesContentDescription>
                        <img src="/img/service/zam_provod_1.jpg" alt=""/>
                        <div>

                            <p> Проводка в квартире или собственном доме находится в состоянии постоянной нагрузки из-за
                                мощных, постоянно работающих электроприборов.
                                Поэтому изношенные, не рассчитанные на современную технику инженерные коммуникации
                                необходимо менять.
                                Если электрика выйдет из строя, ценой легкомыслия станет возгорание или порча
                                дорогостоящего
                                оборудования.</p>
                            <br/>
                            <p>Сделав хороший ремонт не заменив проводку, есть риск потерять много денег и времени.</p>
                            <span>Подробнее...</span>
                        </div>
                    </ServicesContentDescription>
                </ServicesContentCard>
                <ServicesContentCard onClick={() => openImage('elektrika-v-novostroyke')}>

                    <h3>Электрика в новостройке «под ключ»</h3>

                    <ServicesContentDescription>
                        <img src="/img/service/elektrika_novostroyka.jpg" alt=""/>
                        <div>

                            <p>Подключение электрики под ключ в новостройке – это первое, что делают владельцы новой
                                квартиры. И это вполне логично, ведь во время отделочных работ необходима электроэнергия
                                для подключения электроинструментов и освещения. Электромонтаж выполняется мастерами в
                                первую очередь, так как провода и кабеля прокладываются внутри стен и потолков.</p>
                            <br/>
                            <p>Поможем составить проект с расположением ваших розеток и освещения. Предложим наилучшие
                                решения исходя из опыта работ!</p>
                            <span>Подробнее...</span>
                        </div>
                    </ServicesContentDescription>
                </ServicesContentCard>
                <ServicesContentCard onClick={() => openImage('elektromontazh-v-ofisah-i-torgovyih-obektah')}>

                    <h3>Электромонтаж в офисе и торговых объектах</h3>

                    <ServicesContentDescription>
                        <img src="/img/service/montazh_v_ofisah.jpg" alt=""/>
                        <div>

                            <p>Команда «ElectroHelp» оказывает услуги электромонтажа в офисах и торговых объектов любых
                                типов и масштабов
                                «под ключ» с гарантиями. Мы выполняем весь цикл работ, включая проектирование, поставку
                                электротехнического оборудования и материалов, общестроительные и монтажные,
                                электромонтажные работы, пуск и наладку, сервисное обслуживание введенных в эксплуатацию
                                систем электроснабжения.</p>
                            <br/>
                            <p></p>
                            <span>Подробнее...</span>
                        </div>
                    </ServicesContentDescription>
                </ServicesContentCard>
                <ServicesContentCard onClick={() => openImage('elektrika_v_dome')}>
                    <h3>Электрика в частном доме «под ключ»</h3>
                    <ServicesContentDescription>
                        <img src="/img/service/elektrika_v_dome.jpg" alt=""/>
                        <div>

                            <p>Электромонтажные работы в доме — область повышенной ответственности. От них зависят
                                пожарная безопасность дома и сохранность электрооборудования. Халатное отношение к
                                электрике является частой причиной коротких замыканий и, как следствие, пожаров. Команда «ElectroHelp» осуществляет электромонтажные работы в домах Минска и Минской области на
                                высоком профессиональном уровне. Соблюдение техники безопасности, правильность расчета
                                нагрузки и доступность цены гарантированы.</p>
                            <br/>
                            <p></p>
                            <span>Подробнее...</span>
                        </div>
                    </ServicesContentDescription>
                </ServicesContentCard>
            </ServicesContent>
        </ServicesBlock>
    );
};

export default Services;