import React from 'react';
import {WrapperServicePage} from "../style";
import {Link, useNavigate} from "react-router-dom";
import SEO from "../../../../../constants/SEO";

const ElektrikaVDome = () => {
    const navigate = useNavigate();
    return (
        <WrapperServicePage>
            <SEO
                title={'Электрика в частном доме «под ключ» | ElectroHelp.by'}
                description={'Электрика в частном с гарантией и по доступной цене! Бесплатный выезд на объект!'}/>
            <span onClick={() => navigate(-1)}>&larr; Назад</span>
            <h1>Электрика в частном доме «под ключ»</h1>
            <p>Электромонтажные работы в доме — область повышенной ответственности. От них зависят
                пожарная безопасность дома и сохранность электрооборудования. Халатное отношение к
                электрике является частой причиной коротких замыканий и, как следствие, пожаров. Команда «ElectroHelp»
                осуществляет электромонтажные работы в домах Минска и Минской области на
                высоком профессиональном уровне. Соблюдение техники безопасности, правильность расчета
                нагрузки и доступность цены гарантированы.</p>
            <p>
                Наша услуга — электрика в доме «под ключ». Заказывая сразу комплекс работ в «ElectroHelp», клиенты
                экономят, ускоряют ремонт или строительство коттеджа, дачи.
            </p>
            <p>
                В современных домах множество потребителей электроэнергии. При подборе электрики важно учитывать
                мощность, которую сможет пропустить проводка и подключенное к ней оборудование. Поэтому электромонтажные
                работы должны выполнять опытные мастера с действующей группой по электробезопасности не ниже третьей. Мы
                соблюдаем правила по правильному электромонтажу, выполняя электрику в домах «под ключ» строго в
                оговоренный с
                клиентом срок. Гарантия на работу — 5 лет.
            </p>
            <h3>ПЕРЕЧЕНЬ УСЛУГ</h3>
            <div className={'service_wrapper_img_block'}>

                <img src="/img/service/elektrika_v_dome.jpg" alt="elektrika_novostroyka"/>

                <div>
                    <p>
                        Команда «ElectroHelp» выполняет электромонтажные работает в Минске, Минской области и загородных
                        домах. Формат сотрудничества «под ключ» подразумевает проектирование электрики, демонтаж старой
                        проводки при необходимости, прокладку новой проводки, установку/замену розеток и выключателей,
                        монтаж электрощита, автоматических выключателей, УЗО, счетчика электроэнергии. Полный перечень
                        работ фиксируется в договоре вместе с итоговой стоимостью.
                    </p>
                    <p>Если дом новый, мы готовы полностью спроектировать его электрику. Наши специалисты подберут
                        материалы и, в соответствии с проектом, выполнят монтаж необходимого оборудования. Работая в
                        формате «под ключ», мы включаем в итоговую цену договора как подготовку проекта, так и
                        формирование всей необходимой исполнительной документации. Краткий список работ «под ключ» в
                        домах:</p>
                    <br/>
                    <ul>
                        <li>Предпроектное обследование — выезд инженера для осмечивания, составление сметного расчета и
                            формирование итоговой стоимости на электрику в вашем загородном доме;
                        </li>
                        <li>Составление проекта электроснабжения;</li>
                        <li>Демонтаж электрощита, проводки и коммутационных аппаратов;</li>
                        <li>Монтаж проводки и формирование нужного количества розеток под электрику;</li>
                        <li>Установка «под ключ» защитного оборудования для вашей электрики — УЗО, автоматических
                            выключателей;
                        </li>
                        <li>Выполнение мелких электромонтажных работ (монтаж светильников, выключателей, розеток).</li>
                    </ul>
                </div>
            </div>
            <h3>Работы проводятся во всех типах домов</h3>
            <h4>Каркасно-щитовых</h4>
            <p>
                Как правило, скрытая проводка прокладывается в металлических трубах (металлорукаве в ПВХ оболочке), до
                нанесения слоя штукатурки. Монтаж внутренней проводки в готовом каркасно-щитовом доме возможен только
                наружным способом в пластиковых электрокоробах.
            </p>
            <h4>Из бревна и бруса</h4>
            <p>Скрытая проводка в доме из бревна должна отвечать требованиям пожарной безопасности. Ее монтаж возможен
                только в металлических трубах (металлорукаве в ПВХ оболочке). Такой вид проводки монтируется на стадии
                строительства перед обшивкой стен, потолка и пола или в уже готовом доме с помощью дверных коробок,
                перекрытий и специальных плинтусов.
            </p>
            <h4>Кирпичных и из газобетона</h4>
            <p>Скрытая проводка в кирпичном доме или доме из газобетона проводится в штробах (в черновой стяжке) в
                электротехнической ПНД, ПВХ гофре.
            </p>
            <h4>Из СИП-панелей</h4>
            <p>Монтаж скрытой проводки в доме из СИП-панелей возможен только на стадии строительства между плитами или
                до начала работ по финишной внутренней отделке помещения. В уже готовом доме возможен только монтаж
                наружной проводки в пластиковых электрокоробах.
            </p>

            <Link to={'/prices'}>&rArr;<span>Наши расценки</span>&lArr;</Link>
        </WrapperServicePage>
    );
};

export default ElektrikaVDome;