import styled from "styled-components";
import {COLOR_DARK, COLOR_ORANGE} from "../../../../styles";

export const SelectServiceBlock = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding: 120px 30px;
    max-width: 1200px;
`

export const WrapperServicePage = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    span {
        cursor: pointer;
        align-self: start;
        margin-left: 20px;
        font-size: 18px;
    }

    span:hover {
        color: ${COLOR_ORANGE};
    }

    h1, h3 {

        color: ${COLOR_ORANGE};
        background: ${COLOR_DARK};
        width: 100%;
        text-align: center;
        margin: 25px 20px;
        padding: 10px;
        border-radius: 10px;

    }

    h3 {
        text-transform: uppercase;
    }

    h4 {
        font-size: 20px;
        margin: 10px;
    }

    h1 {
        font-size: 36px;
    }

    p {
        text-indent: 20px;
    }

    ul {
        list-style: disc;
        padding-left: 30px;
        align-self: start;

        li {
            list-style: disc;
            margin-bottom: 5px;
        }
    }

    a {
        font-size: 24px;
margin: 30px 0 0;
        span {
            margin: 10px 15px 0 15px;
            font-size: 24px;
            text-decoration: underline;

        }

    }

    a:hover span {
        color: ${COLOR_ORANGE};
    }

    .service_wrapper_img_block {
        display: flex;
        width: 100%;

        img {
            min-width: 200px;
            height: 300px;
            object-fit: cover;
            margin: 10px 30px 30px 20px;
            border-radius: 10px;
        }
        
    }

    @media (max-width: 1000px) {
        .service_wrapper_img_block {
            flex-direction: column;


        }
    }
`