import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {WrapperServicePage} from "../style";
import SEO from "../../../../../constants/SEO";

const ZamStaroyProvodki = () => {
    const navigate = useNavigate();


    return (
        <WrapperServicePage>
            <SEO
                title={'Замена старой электропроводки | ElectroHelp.by'}
                description={'Замена старой электропроводки с гарантией и по доступной цене! Бесплатный выезд на объект!'}/>

            <span onClick={() => navigate(-1)}>&larr; Назад</span>
            <h1>Замена старой электропроводки</h1>
            <p>Проводка в квартире или собственном доме находится в состоянии постоянной нагрузки из-за мощных,
                постоянно работающих электроприборов.
                Поэтому изношенные, не рассчитанные на современную технику инженерные коммуникации необходимо менять.
                Если электрика выйдет из строя, ценой легкомыслия станет возгорание или порча дорогостоящего
                оборудования.</p>
            <p>Наша команда «ElectroHelp» выполнит замену проводки в квартире или доме.
                Находясь в рыночной стоимости наши услуги отличаются высоким качеством и гарантией на выполненные
                работы.
                Каждый электрик «ElectroHelp» имеет допуск по электробезопасности не ниже 4-ой группы.
                Мы выезжаем в квартиры и дома, расположенные в Минске и Минской области.</p>

            <h3>ПОЧЕМУ НУЖНО МЕНЯТЬ ЭЛЕКТРОПРОВОДКУ</h3>

            <div className={'service_wrapper_img_block'}>

                <img src="/img/service/zam_provod_1.jpg" alt="zam_provod"/>
                <div>
                    <p>
                        Немало квартир находится в зданиях, построенных до 80-х годов ХХ века, в то время нагрузка на
                        проводку
                        была гораздо ниже.
                        В кабелях устанавливалась алюминиевая сердцевина — во многих домах Минска такие провода стоят и
                        сейчас.
                        Алюминиевая основа проводки в квартире не рассчитана на уровень сегодняшней нагрузки и бывают
                        случаи,
                        когда электрика «не выдерживает».
                    </p>
                    <p>
                        Пример: квартира в доме, построенном в 60-х годах, находящемся в Минске, рассчитана на 3 кВт.
                        Один кондиционер и холодильник превышает порог нагрузки на существующую проводку.
                        Современные теплые полы квартиры или электроплита по мощности достигают до 10 кВт.
                        Поэтому замена проводки на надежную, медную обязательна.
                    </p>

                    <p>Электрикой нужно заниматься и по другой причине.
                        Проводка в среднем служит не больше 25 лет — даже если медная жила цела, разрушится изоляция,
                        без
                        замены
                        проводки может произойти пожар.</p>
                    <p>Учтите: даже использовать для замены алюминиевые провода не рекомендуется. Они стоят дешевле
                        медных,
                        но
                        такая проводка намного хуже выдерживает токовую нагрузку, и нельзя ради чрезмерно низкой цены
                        жертвовать
                        безопасностью. При большей стоимости медный провод дольше служит, окупаясь несколько раз. Наш
                        электрик
                        подберет подходящий по цене вариант и качественно поменяет старую проводку в квартире.</p>
                </div>
            </div>

            <h3>ЭТАПЫ РАБОТ</h3>

            <ul>
                <li>Заявка, предварительное согласование цены, условий и выезд электрика. Сотрудник приедет в квартиру в
                    назначенное время, без нарушений графика. На месте электрик оценивает имеющиеся коммуникации, объем
                    работы, необходимость замены или модернизации оборудования, диагностирует проводку и подготавливает
                    электропроект. В проекте указывается схема квартиры, места розеток, бытовой электротехники,
                    освещения. Удобнее, если перед заменой проводки потребители электроэнергии разделяются на группы;
                </li>
                <li>Подготовка сметы с итоговой стоимостью. Команда «ElectroHelp» работает по договору, фиксируя в нем
                    цены
                    выполнения работ и иные условия;
                </li>
                <li>Приобретение материалов для замены проводки. Ознакомившись с электросистемой квартиры, наш сотрудник
                    даст рекомендации для покупки кабелей и прочих составляющих. По желанию заказчика электрик может
                    купить кабель и другие необходимые комплектующие;
                </li>
                <li>Демонтаж старых комплектующих. Электрика отключается, мастер убирает распределительные коробки,
                    розетки, старые трассы, расположенные внутри квартиры;
                </li>
                <li>Замена проводки и монтаж электроустройств. По всей территории квартиры выполняется разводка, наш
                    электрик устанавливает выключатели, розетки, щиты. В доме при замене проводки выполняется
                    заземление. Одновременно с проводкой прокладываются слаботочные линии для ТВ, интернет-подключения,
                    телефона и т.д.;
                </li>

            </ul>
            <h3>ОСОБЕННОСТИ ЦЕНООБРАЗОВАНИЯ</h3>

            <ul>
                <li>Cпособа монтажа при замене: скрыто или открыто (проводка укладывается в кабель-канал);</li>
                <li>Oбъема работ по замене — подключение автоматов, теплых полов и прочих систем, влияющих на цену и объем работы;</li>
                <li>Материала стен, в которые укладывается проводка — от него зависит способ монтажа и его цены;</li>
                <li>Замена электропроводки по потолку или горизонтальное штробление стен;</li>
                <li>Метраж кабеля для замены и т.д.</li>
            </ul>

            <Link to={'/prices'}>&rArr;<span>Наши расценки</span>&lArr;</Link>
        </WrapperServicePage>
    );
};

export default ZamStaroyProvodki;