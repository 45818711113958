import React, {useState} from 'react';
import {BurgerButton, FutureBlock, HeaderBlock, HeaderLinks, HeaderTitle, WindowClose} from "./style";
import Burger from "./burger";
import {Link, useLocation} from "react-router-dom";

const Header = () => {

    const [state, setState] = useState(false);
    const location = useLocation();
    const locationName = location.pathname.split('/')[1] ? location.pathname.split('/')[1] : 'home';
    const SetStateFalse = () => {
        window.scroll({top: 0})
        if (state) {
            setState(false)

        }
    }

    return (
        <>
            <HeaderBlock $state={state}>
                <HeaderTitle><a href="/"><img src="/img/logo.png" alt=""/></a></HeaderTitle>

                <HeaderLinks $state={locationName}>
                    <Link to='/' onClick={SetStateFalse} className={'home'}>Главная</Link>
                    <Link to='gallery' onClick={SetStateFalse} className={'gallery'}>Галерея</Link>
                    <Link to='prices' onClick={SetStateFalse} className={'prices'}>Расценки</Link>
                    <Link to='services' onClick={SetStateFalse} className={'services'}>Услуги</Link>
                    <Link to='contacts' onClick={SetStateFalse} className={'contacts'}>Контакты</Link>
                </HeaderLinks>
                <BurgerButton onClick={() => setState(!state)} $state={state}>
                    <span></span><span></span><span></span>
                </BurgerButton>
                <FutureBlock></FutureBlock>


            </HeaderBlock>
            {state && <WindowClose onClick={() => setState(false)}/>}
            <Burger state={state} setState={setState}/>
        </>
    )
};

export default Header;