import styled from "styled-components";
import {COLOR_DARK, COLOR_ORANGE} from "../../../styles";

export const ServicesBlock = styled.section`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding: 140px 30px;
    max-width: 1200px;

    h1 {
        font-size: 50px;
        font-family: Orelega_One, 'sans-serif';
        font-weight: normal;
        margin: 0 auto 50px;
        width: 100%;
        text-align: center;
    }

`

export const ServicesContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;

`
export const ServicesContentCard = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;
    min-height: 300px;
    border-radius: 20px;
    border: 2px solid ${COLOR_DARK};
    margin-bottom: 50px;
    box-shadow: 15px 15px 15px 5px grey;
    transition: 0.5s;
    background: ${COLOR_DARK};
    padding: 20px 30px;

    &:hover {
        transform: translate(10px, 10px);
        transition: 0.5s;
        box-shadow: none;
        cursor: pointer;
    }

    h3 {
        color: ${COLOR_ORANGE};
        font-size: 24px;
        margin-bottom: 20px;
        text-align: center;
    }


`

export const ServicesContentDescription = styled.div`

    display: flex;
    align-items: center;

    img {
        min-width: 200px;
        width: 200px;
        height: 200px;
        border-radius: 20px;
        margin-right: 30px;
        border: 1px solid black;
        object-fit: cover;
    }

    div {
        display: flex;
        height: 100%;
        flex-direction: column;

        align-items: center;
    }


    p {
        color: white;
        text-indent: 20px;
    }

    p:last-of-type {
        margin-bottom: 30px;

    }

    span {
        color: ${COLOR_ORANGE};
        align-self: end;
        margin-top: auto;
    }


    @media (max-width: 700px) {

        flex-direction: column;
        
        img {
            margin: 0 0 20px 0;
            
            width: 250px;
            height: 250px;
            min-height: 250px;
        }
    }


`