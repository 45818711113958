import styled from "styled-components";
import {COLOR_DARK, COLOR_ORANGE} from "../../../../styles";

export const HomeTitleBlock = styled.section`
    width: 100%;
    z-index: 5;
    padding: 80px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;


    @media (max-width: 1000px) {

        padding-bottom: 0;

    }
    @media (max-width: 700px) {
        flex-direction: column;

    }
`

export const FirstTitle = styled.div`
    background: ${COLOR_DARK};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    h1 {
        font-size: 40px;
        font-family: Orelega_One, 'sans-serif';
        font-weight: normal;
        text-align: center;
        margin: 20px 50px;
        color: ${COLOR_ORANGE};
    }

    @media (max-width: 700px) {

        h1 {
            font-size: 34px;
            margin: 30px 50px;
        }

    }
`

export const LogoTitle = styled.div`
    height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    .slick-slider {
        width: 100%;
        overflow: hidden;
    }

    .slick-list {
        display: flex;
        width: 100%;
        height: 100%;
    }

    .slick-track {
        display: flex;
        height: 100%;
    }


    .logo_img_block {

        height: 400px;
        
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media (max-width: 1000px) {
        justify-content: center;
    }
`

export const LogoTitleContent = styled.div`
    
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: end;
    width: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), ${COLOR_DARK}), linear-gradient(to bottom, rgba(255, 255, 255, 0), ${COLOR_DARK}), linear-gradient(to top, rgba(255, 255, 255, 0), ${COLOR_DARK}) center no-repeat;

`
export const LogoTitleUl = styled.ul`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-right: 3%;
    width: 500px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), ${COLOR_DARK});

    li {
        color: white;
        margin-bottom: 15px;
        font-family: Roboto, sans-serif;

        text-align: center;
    }

    li:first-of-type, li:last-of-type {
        font-size: 18px;

        color: ${COLOR_ORANGE};
    }

    @media (max-width: 1000px) {
        background: none;
        margin: 0 auto;
        padding: 25px;
    }
`



export const SecondTitle = styled.div`
    background: ${COLOR_DARK} ;
    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;


    h2 {
        margin: 15px 50px;
        font-size: 35px;
        font-family: Orelega_One, 'sans-serif';
        font-weight: normal;
        color: ${COLOR_ORANGE};
    }
    
    @media (max-width: 700px) {
        
        h2 {
            font-size: 28px;
            margin: 25px 50px;
        }
    }
    `