import React from 'react';
import {WrapperServicePage} from "../style";
import {Link, useNavigate} from "react-router-dom";
import SEO from "../../../../../constants/SEO";

const ElektrikaVNovostroyke = () => {
    const navigate = useNavigate();

    return (
        <WrapperServicePage>
            <SEO
                title={'Электрика в новостройке «под ключ» | ElectroHelp.by'}
                description={'Электрика в новостройке с гарантией и по доступной цене! Бесплатный выезд на объект!'}/>
            <span onClick={() => navigate(-1)}>&larr; Назад</span>
            <h1>Электрика в новостройке «под ключ»</h1>
            <p>Подключение электрики под ключ в новостройке – это первое, что делают владельцы новой квартиры. И это
                вполне логично, ведь во время отделочных работ необходима электроэнергия для подключения
                электроинструментов и освещения.
                Электромонтаж выполняется мастерами в первую очередь, так как провода и кабеля прокладываются внутри
                стен и потолков.</p>
            <p>Сегодня, перед заселением в новостройку, в большинстве случаев требуются дополнительные траты, так как
                квартиры в новых домах сдаются без ремонта. Необходимо подключение электрических систем для электроплит
                (высотные дома не подключаются к подаче газа) и пользования бытовой техникой. Поэтому от того, насколько
                правильно и быстро спланирован и проведен монтаж электропроводки, зависит быстрота заселения, а также
                комфорт и безопасность жизнедеятельности. жизнедеятельности. жизнедеятельности.
            </p>
            <h3>ОСОБЕННОСТИ электропроводки в новостройке «под ключ»</h3>
            <div className={'service_wrapper_img_block'}>

                <img src="/img/service/elektrika_novostroyka1.jpg" alt="elektrika_novostroyka"/>

                <div>
                    <p>Многие решают сэкономить и выполнить все самостоятельно, без привлечения специалистов. Такое
                        решение
                        вполне жизнеспособно, но только при условии наличия у владельца жилья опыта в электромонтажных
                        работах,
                        времени и технических возможностей. Но в большинстве случаев лучше довериться профессионалам
                        своего
                        дела.
                    </p>
                    <p>Квалифицированные специалисты команды «ElectroHelp» обладают большим опытом работы на множестве
                        объектов, включая новые многоэтажные жилые дома, для которых мы предоставляем следующие
                        услуги:</p>
                    <br/>
                    <ul>
                        <li>Расчет силовых линий с учетом количества потребителей электроэнергии</li>
                        <li>Проверка кабельных линий</li>
                        <li>Создание электропроекта (при необходимости)</li>
                        <li>Замена электроустановочных изделий (розетки, выключатели и т.д.)</li>
                        <li>Создание слаботочных сетей (телефония, TV, домофон, интернет и т. д.)</li>
                        <li>Полная/частичная замена электропроводки</li>
                    </ul>
                </div>
            </div>
            <h3>Почему важен квалифицированный электрик</h3>
            <p>
                Выбор квалифицированного электрика для электромонтажных работ в новостройке — ключ к успеху вашего
                проекта. Профессиональные электромонтажники не только гарантируют безопасность и качество работ, но и
                помогают в оптимизации электроснабжения с учетом всех потребностей и пожеланий заказчика. От прокладки
                кабеля до штробления стен под проводку, каждый этап работ требует опыта и знаний.
            </p>
            <p>Подводя итог, можно сказать, что электромонтаж в новостройке — это не просто установка розеток и
                выключателей, это комплексный процесс, требующий профессионального подхода и использования современных
                технологий и качественных материалов. Правильно организованная электрика в новостройке обеспечивает
                безопасность, комфорт и энергоэффективность вашего дома на долгие годы.</p>
            <p>Команда «ElectroHelp» – это ваш шанс сделать электрику быстро, качественно и с соблюдением всех
                прописанных в договоре сроков. Обращаясь за помощью к нам, вы получаете следующие преимущества:
            </p>
            <ul>
                <li>Профессионализм специалистов – у нас работают настоящие мастера своего дела с опытом работы от 5-10
                    лет и выше
                </li>
                <li>Персональный подход и индивидуальная цена – мы рассчитываем стоимость работ строго с учетом
                    особенностей вашей квартиры, а не предлагаем всем неоправданно высокие цены
                </li>
                <li>Оперативность – наша цель, чтобы вы въехали в долгожданную квартиру как можно скорее, поэтому мы
                    смонтируем все в кратчайшие сроки!
                </li>
                <li>Гарантия на все работы – 5 лет
                </li>
            </ul>

            <Link to={'/prices'}>&rArr;<span>Наши расценки</span>&lArr;</Link>
        </WrapperServicePage>
    );
};

export default ElektrikaVNovostroyke;