import styled from "styled-components";
import {motion} from "framer-motion";
import {animateRightX} from "../../../../constants/framer-motion";
import {COLOR_ORANGE, COLOR_ORANGE_HOVER} from "../../../../styles";

export const WrapperOrder = styled.section`
    width: 100%;
    min-height: 700px;
    z-index: 5;
    
`
export const WrapperContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0)), linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0)),  url("/img/colorful.jpg") no-repeat center;
    background-size: cover;
    
    @media (max-width: 1100px) {
        flex-direction: column;
        background-position: left;
    }

`
export const DescriptionBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px;
    max-width: 600px;
    justify-content: center;
    align-items: center;

    p {
        margin: 3px;
        font-size: 26px;
        text-align: center;
    }

    p:last-of-type {
        font-size: 32px;
    }

    div {
        display: flex;
        justify-content: center;
        width: max-content;
        height: max-content;
        margin-top: 50px;

        img {
            width: 250px;
            height: 150px;
        }
    }

    @media (max-width: 1100px) {
        margin: 70px 20px 20px;

    }
`

export const OrderBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    padding: 0 20px;
    margin: 20px 20px 20px 0;
    
    @media (max-width: 1100px) {
        margin: 40px 0 20px;
    }
    @media (max-width: 700px) {
        flex-direction: column;
    }

`
export const OrderDescription = styled(motion.div).attrs(() => ({
    initial: "hidden",
    whileInView : "visible",
    viewport: {amount: 0.1, once: true},
    variants: animateRightX}))
    `
        display: flex;
        max-width: 150px;
        min-width: 155px;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 340px;
        background: #e1e1e1;
        border-radius: 20px;
        padding: 10px;
        position: relative;

        p {
            font-size: 16px;
            text-align: center;
        }

        &::after {
            position: absolute;
            content: '';

            right: -30px;
            bottom: 20px;
            width: 0;
            height: 0;
            border-top: 15px solid transparent;
            border-left: 30px solid rgb(225, 225, 225);;
            border-bottom: 15px solid transparent;
        }

        @media (max-width: 700px) {
            margin-bottom: 0;
            max-width: 370px;
            order: 2;
            &::after {
                right: 40px;
                bottom: -45px;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-top: 30px solid rgb(225, 225, 225);;
            }
        }

    `

export const FormBlock = styled.div`
    background: url("/img/phone1.png") no-repeat;
    background-size: cover;
    border-radius: 10px;
    padding: 20px 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    min-width: 400px;
    min-height: 550px;
    position: relative;
    margin-left: 130px;

    img {
        position: absolute;
        top: 30px;
        left: -160px;
        width: 200px;
    }

    h2 {

        font-size: 24px;
    }

    form {

        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;

        label {
            margin: 10px 5px 5px;

            span {
                color: red;
            }
        }

        input {
            width: 80%;
            height: 40px;
            border: 2px solid black;
            border-radius: 10px;
            padding: 5px 10px;
            font-size: 18px;
            cursor: pointer;
        }

        textarea {
            width: 80%;
            border: 2px solid black;
            border-radius: 10px;
            padding: 5px 10px;
            font-size: 18px;
            resize: none;
            cursor: pointer;
        }

        .button_form {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 200px;
            height: 50px;
            border-radius: 10px;
            background: ${COLOR_ORANGE};
            margin: 30px auto 0;
            caret-color: rgba(255, 255, 255, 0);

            &:hover {
                background: ${COLOR_ORANGE_HOVER};
            }

            &:active {

                box-shadow: 0 0 15px 5px rgb(255, 251, 66);
                width: 195px;
                height: 45px;
                margin: 22.5px 0 2.5px 0;
            }
        }
    }


    @media (max-width: 700px) {
        margin: 30px auto 50px;
        order: 1;
        min-width: 280px;
        img {
            display: none;
        }
    }

`

export const OrderMobileImg = styled.div`
    display: none;

    @media (max-width: 700px) {
        display: flex;
        order: 3;
        width: 100%;
        height: 100%;
        margin: 20px 0;
        max-width: 400px;
        caret-color: rgba(255, 255, 255, 0);
        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

`