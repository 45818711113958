import React from 'react';
import {PricesBlock, PricesNotes} from "./style";
import SEO from "../../../constants/SEO";

const Prices = () => {

    window.scroll({top: 0})

    return (
        <PricesBlock>
            <SEO
                title={'Цены на электромонтажные работы. Прейскурант услуг электрика | ElectroHelp.by'}
                description={'Подробная таблица цен на элетромонтажные работы. Качественные услуги по хорошей цене!'}/>
            <h1>Наши расценки!</h1>
            <table>
                <thead>
                <tr>
                    <th>Виды работ</th>
                    <th>Ед. изм.</th>
                    <th>Цены, BYN</th>
                </tr>
                </thead>
                <tbody>
                <tr className={'table_rough_work'}>
                    <td colSpan={3}>Черновые работы</td>
                </tr>
                <tr>
                    <td>Электроточка (гипсокартон)</td>
                    <td>шт.</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>Электроточка (пеноблок)</td>
                    <td>шт.</td>
                    <td>13</td>
                </tr>
                <tr>
                    <td>Электроточка (кирпич)</td>
                    <td>шт.</td>
                    <td>15</td>
                </tr>
                <tr>
                    <td>Электроточка (бетон)</td>
                    <td>шт.</td>
                    <td>20</td>
                </tr>
                <tr>
                    <td>Изготовление отверстий под расп. коробки</td>
                    <td>шт.</td>
                    <td>30</td>
                </tr>
                <tr>
                    <td>Монтаж накладной расп. коробки</td>
                    <td>шт.</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>Расключение распределительной коробки</td>
                    <td>шт.</td>
                    <td>30</td>
                </tr>
                <tr>
                    <td>Штробление до 20×20 мм (пеноблок)</td>
                    <td>м.п.</td>
                    <td>3</td>
                </tr>
                <tr>
                    <td>Штробление до 20×20 мм (кирпич)</td>
                    <td>м.п.</td>
                    <td>4</td>
                </tr>
                <tr>
                    <td>Штробление до 20×20 мм (бетон)</td>
                    <td>м.п.</td>
                    <td>6</td>
                </tr>
                <tr>
                    <td>Изготовление ниши под электрический щит (пеноблок)</td>
                    <td>шт.</td>
                    <td>30-80</td>
                </tr>
                <tr>
                    <td>Изготовление ниши под электрический щит (кирпич)</td>
                    <td>шт.</td>
                    <td>80-150</td>
                </tr>
                <tr>
                    <td>Изготовление ниши под электрический щит (бетон)</td>
                    <td>шт.</td>
                    <td>150-350</td>
                </tr>
                <tr>
                    <td>Прокладка кабеля открытым способом с сечением жил 1,5-2,5 мм<sup>2</sup></td>
                    <td>м.п.</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Прокладка кабеля открытым способом с сечением жил 4-16 мм<sup>2</sup></td>
                    <td>м.п.</td>
                    <td>3.2</td>
                </tr>
                <tr>
                    <td>Разводка слаботочного кабеля</td>
                    <td>м.п.</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Затягивание кабеля в трубы (ПВХ, ПНД), металлорукав, гофру</td>
                    <td>м.п.</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Монтаж гофры, трубы ПВХ, ПНД, металлорукава, до Ø25 мм</td>
                    <td>м.п.</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Монтаж гофры, трубы ПВХ, ПНД, металлорукава, Ø25-50 мм</td>
                    <td>м.п.</td>
                    <td>3</td>
                </tr>
                <tr>
                    <td>Сверление сквозных отверстий до ø32мм (пеноблок)</td>
                    <td>см.п</td>
                    <td>0.2</td>
                </tr>
                <tr>
                    <td>Сверление сквозных отверстий до ø32мм (кирпич)</td>
                    <td>см.п</td>
                    <td>0.3</td>
                </tr>
                <tr>
                    <td>Сверление сквозных отверстий до ø32мм (бетон)</td>
                    <td>см.п</td>
                    <td>0.5</td>
                </tr>
                <tr>
                    <td>Установка накладного распределительного щита</td>
                    <td>шт.</td>
                    <td>от 20</td>
                </tr>
                <tr>
                    <td>Установка встраиваемого распределительного щита</td>
                    <td>шт.</td>
                    <td>от 50</td>
                </tr>
                <tr>
                    <td>Монтаж модульной автоматики (диф, узо, автомат, и др.)</td>
                    <td>шт.</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>Монтаж кабель-канала ПВХ, сечение до 25Х25 мм</td>
                    <td>м.п.</td>
                    <td>3</td>
                </tr>
                <tr>
                    <td>Монтаж кабель-канала ПВХ, сечение от 25Х25 мм</td>
                    <td>м.п.</td>
                    <td>5</td>
                </tr>
                <tr>
                    <td>Перетяжка оптоволокна или витой парой</td>
                    <td>м.п.</td>
                    <td>4</td>
                </tr>
                <tr>
                    <td>Монтаж теплого пола (маты)</td>
                    <td>м.п.</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>Подключение патрона для временного освещения</td>
                    <td>шт.</td>
                    <td>3</td>
                </tr>
                <tr className={'table_finishing_work'}>
                    <td colSpan={3}>Чистовые работы</td>
                </tr>
                <tr>
                    <td>Подключение и монтаж внутренней точки (розетка, выключатель, кнопка)</td>
                    <td>шт.</td>
                    <td>4</td>
                </tr>
                <tr>
                    <td>Подключение и монтаж накладной точки (розетка, выключатель, кнопка)</td>
                    <td>шт.</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>Подключение и монтаж розетки для электроплиты</td>
                    <td>шт.</td>
                    <td>15</td>
                </tr>
                <tr>
                    <td>Подключение и монтаж терморегулятора</td>
                    <td>шт.</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>Установка встраиваемых светильников в готовые отверстия</td>
                    <td>шт.</td>
                    <td>от 5</td>
                </tr>
                <tr>
                    <td>Установка настенного светильника (бра)</td>
                    <td>шт.</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>Установка подвесного светильника, люстры (обычной)</td>
                    <td>шт.</td>
                    <td>25</td>
                </tr>
                <tr>
                    <td>Сборка люстры</td>
                    <td>шт.</td>
                    <td>от 20</td>
                </tr>
                <tr>
                    <td>Монтаж светодиодной ленты</td>
                    <td>м.п.</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>Установка и подключение вентилятора</td>
                    <td>шт.</td>
                    <td>25</td>
                </tr>
                <tr>
                    <td>Подключение варочной поверхности</td>
                    <td>шт.</td>
                    <td>30</td>
                </tr>
                </tbody>
            </table>
            <PricesNotes>
                <h2>Примечания</h2>
                <div>
                <p>- Стоимость работ может отличаться от заявленных, как в большую, так и в меньшую сторону.
                    Она зависит от объемов, сложности и высоты работ.</p>
                <p>- Минимальная сумма выезда к клиенту для работы составляет 50р.</p>
                <p>- Стоимость на внешние электромонтажные работы зависят от сезонности и погодных условий.</p>
                <p>- Консультация по телефону бесплатно.</p>
                <p>- Консультация с выездом на объект входит в стоимость работ, либо 20р.</p>
                </div>
            </PricesNotes>
        </PricesBlock>
    );
};

export default Prices;