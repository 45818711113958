import styled from "styled-components";
import {COLOR_DARK} from "../../../styles";

export const FixedFooterBlock = styled.section `
    display: flex;
    height: 100px;
    width: 100%;
    background: ${COLOR_DARK};
    flex: 0 0 auto;
    position: relative;
    div{
        display: none;
    }
    @media (max-width: 700px) {

        height: 150px;
    }
    @media (max-height: 600px) {

        div{
            display: flex;
        }
        height: max-content;

    }
`

