import styled from "styled-components";

export const MainBlock = styled.section`

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    z-index: 5;
    width: 100%;
    overflow: hidden;
    background: white;
    
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    height: 100%;
    align-items: center;
    width: 100%;
    `