import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {WrapperServicePage} from "../style";
import SEO from "../../../../../constants/SEO";

const MontazhVOfisah = () => {
    const navigate = useNavigate();
    return (
        <WrapperServicePage>
            <SEO
                title={'Электромонтаж в торговых центрах и офисах | ElectroHelp.by'}
                description={'Электрика в торговых центрах и офисах с гарантией и по доступной цене! Бесплатный выезд на объект!'}/>
            <span onClick={() => navigate(-1)}>&larr; Назад</span>
            <h1>Электромонтаж в торговых центрах и офисах</h1>
            <p>Неправильно смонтированная электрика в магазине, торговом центре или офисе – это в первую очередь
                повышенные
                риски для работников и посетителей. При возникновении ЧП или при внезапной проверке в случае выявления
                несоответствий ответственные лица из руководства ТЦ или офиса несут не только административную, но и
                уголовную
                ответственность. Поэтому монтаж электрики на торговых точках должны выполнять только авторизованные,
                сертифицированные подрядчики.
            </p>
            <p>
                В команде «ElectroHelp» работают только штатные электромонтажники с соответствующими разрядами и
                допусками. Мы оказываем услуги официально, по договору, с предоставлением гарантий качества на все виды
                работ 5 лет. У нас есть допуски для выполнения работ в сетях с напряжением до 1000В на
                торговых и коммерческих объектах всех типов.
            </p>
            <h3>Особенности монтажа электрики</h3>
            <div className={'service_wrapper_img_block'}>

                <img src="/img/service/montazh_v_ofisah_1.jpg" alt="montazh_v_ofisah"/>
                <div>

                    <p>Монтаж электрики в ТЦ, магазине или офисе – это целый комплекс сложных мероприятий по сбору и
                        утверждению документации, проектированию, снабжению, выполнению электромонтажа. Не у каждого
                        мастера
                        есть допуски для выполнения таких работ, так как к объекту предъявляются повышенные требования
                        безопасности. Наиболее важно соблюдение всех требований действующих государственных регламентов,
                        в числе
                        которых ГОСТ, СНиП, ПУЭ, СанПиН и некоторые своды ТУ. Наши электромонтажники отлично знают и
                        соблюдают
                        все эти требования. Как мы работаем:</p>
                    <ul>
                        <li>В заранее согласованное время выезжаем на объект для сбора исходных данных, снятия замеров и
                            изучения имеющейся у заказчика технической документации;
                        </li>
                        <li>Проведение необходимых расчетов и проектирование с учетом требований регламентов, сбор и
                            утверждение
                            документации;
                        </li>
                        <li>Снабжение объекта – закупаем по дилерским ценам и доставляем все материалы и
                            электротехническое
                            оборудование;
                        </li>
                        <li>Монтаж электрики в магазине или торговом центре по согласованному и утвержденному графику,
                            пусконаладочные работы.
                        </li>
                    </ul>
                    <p>
                        В итоге мы выдаем заказчику акт о выполненных работах и выписываем гарантию. Мы выполняем весь
                        комплекс
                        работ, включая установку электрических щитков и щитового оборудования, разведение проводки,
                        монтаж точек
                        подключения (розеток, выключателей, датчиков). Также выполняем монтаж силовых устройств –
                        трансформаторов и стабилизаторов, генераторов автономного электроснабжения, обустраиваем
                        заземление,
                        молниезащиту. То есть, в нашей компании можно получить весь комплекс услуг в рамках монтажа
                        электрики в
                        ТЦ, магазинах и офисах.
                    </p>
                </div>
            </div>


            <h3>Услуги профессионального монтажа электрики в ТЦ, магазинах и офисах</h3>
            <p>
                Команда «ElectroHelp» оказывает услуги профессионального монтажа электрики любых масштабов. Очень ответственно подходим к работе. Главные
                плюсы сотрудничества с нами:
            </p>
            <br/>
            <ul>
                <li>Высокий профессионализм штатных мастеров и наличие допусков для выполнения всех видов
                    электромонтажных работ на коммерческих объектах, включая магазины, торговые центры и другие точки
                    продаж;
                </li>
                <li>На все виды работ даем гарантию на 5 лет, при возникновении любых гарантийных случаев быстро и в
                    полной мере устраняем проблему, оказываем услуги модернизации смонтированных сетей электроснабжения;
                </li>
                <li>Напрямую сотрудничаем с крупными производителями материалов и электротехнической продукции, поэтому
                    не только полностью уверены в качестве, но и поставляем их по лучшим ценам на дилерских условиях;
                </li>
                <li>Работаем официально по договору, в котором прописываются все условия сотрудничества. К пакету
                    документации прикрепляется детальная смета, в которой расписаны все виды работ с ценами на
                    оборудование и материалы;
                </li>
                <li>Выезжаем на объекты по Минску и Минской области, реализовываем «под ключ» проекты любых масштабов,
                    выполняем монтаж электрики в ТЦ, специализированных магазинах, на торговых точках и офисах;
                </li>
                <li>Всегда соблюдаем требования действующих регламентов, поэтому после сдачи объекта в эксплуатацию у
                    владельцев не возникает проблем с контролирующими органами при проведении инспекций;
                </li>
                <li>Консультируем своих клиентов по всем интересующим техническим и финансовым вопросам, оказываем
                    услуги информационной поддержки на этапе эксплуатации объекта.
                </li>
            </ul>
            <Link to={'/prices'}>&rArr;<span>Наши расценки</span>&lArr;</Link>
        </WrapperServicePage>
    );
};

export default MontazhVOfisah;