import React from 'react';
import {useParams} from "react-router-dom";
import ZamStaroyProvodki from "./pages/zam_straroy_provodki";
import {SelectServiceBlock} from "./style";
import ElektrikaVNovostroyke from "./pages/elektrika_v_novostroyke";
import MontazhVOfisah from "./pages/montazh_v_ofisah";
import ElektrikaVDome from "./pages/elektrika_v_dome";

const SelectService = () => {

    const params = useParams()
    const paramsName = params.name;

    return (
        <SelectServiceBlock>
            {paramsName === 'zamena-staroy-elektroprovodki' && <ZamStaroyProvodki/>}
            {paramsName === 'elektrika-v-novostroyke' && <ElektrikaVNovostroyke/>}
            {paramsName === 'elektromontazh-v-ofisah-i-torgovyih-obektah' && <MontazhVOfisah/>}
            {paramsName === 'elektrika_v_dome' && <ElektrikaVDome/>}
        </SelectServiceBlock>
    );
};

export default SelectService;